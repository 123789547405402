// PermissionsRoute.js
import React from 'react';
import { Navigate, useParams } from "react-router-dom";
import usePermissions from '../hooks/usePermissions';

const PermissionsRoute = ({ requiredPermission, children }) => {
  const { uid } = useParams();
  const noPermissionURL = `/patient/${uid}/update`;
  const { hasPermission, loading } = usePermissions();

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (!hasPermission(requiredPermission)) {
    return <Navigate to={noPermissionURL} replace/>;
  }
  else{
    return <>{children}</>;;
  }
};

export default PermissionsRoute;
