import { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../providers/AuthContext";
import { database } from '../firebaseConfig';
import { ref, onValue, off } from "firebase/database";

const usePermissions = () => {
  const { currentUser } = useContext(AuthContext);
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;

    const permissionsRef = ref(database, `permissions/${currentUser.uid}`);
    const fetchPermissions = (snapshot) => {
      if (snapshot.exists()) {
        const userPermissions = snapshot.val();
        setPermissions(userPermissions || {});
      }
      else {
        setPermissions({});
      }
      setLoading(false);
    };
    onValue(permissionsRef, fetchPermissions);

    return () => {
      off(permissionsRef, 'value', fetchPermissions);
    };
  }, [currentUser]);

  const hasPermission = (requiredPermission) => {
    return permissions[requiredPermission] || false;
  };

  return { hasPermission, loading };
};

export default usePermissions;
