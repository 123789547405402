import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import { useParams } from "react-router-dom";
import { AuthContext } from "../../providers/AuthContext";
import { HeartbeatContext } from "../../providers/HeartbeatContext";

import ContrastSensitivity12 from "../../components/Charts/ContrastSensitivity12";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { ref, update, onValue, off, get } from "firebase/database";
import { database } from "../../firebaseConfig";

import StartTestButton from "../../components/buttons/StartTestButton";
import ContrastSensitivityPDF from "../../components/PDFdocuments/ContrastSensitivityPDF";

const ContrastSensitivityForm = () => {
  const [eye, setEye] = React.useState("");
  const [cpd, setCPD] = React.useState("3");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const { uid } = useParams();
  const { currentUser, updateLastTestDate } = useContext(AuthContext);
  const { t } = useTranslation();

  // region Check Status
  const { isAppAlive } = useContext(HeartbeatContext);
  const [contrastSensitivityStatus, setContrastSensitivityStatus] = React.useState(null);

  // status is readed at the beginning, even if the app is not alive
  useEffect(() => {
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;
    const contrastSensitivityStatusRef = ref(database, `contrast_sensitivity_status/${currentUser.uid}`);
    get(contrastSensitivityStatusRef).then((snapshot) => {
      const contrastSensitivityVal = snapshot.val();
      if (contrastSensitivityVal && contrastSensitivityVal?.actual_user === uid) {
        setContrastSensitivityStatus(contrastSensitivityVal);
      }
      else {
        setContrastSensitivityStatus({
          in_test: false
        });
      }
    });
  }, [currentUser]);

  // status is readed when the app is alive, besides, the status is updated when it changes
  useEffect(() => {
    if (!isAppAlive) return;
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;

    const contrastSensitivityStatusRef = ref(database, `contrast_sensitivity_status/${currentUser.uid}`);
    const fetchConstrastSensitivityStatus = (snapshot) => {
      const contrastSensitivityVal = snapshot.val();
      if (contrastSensitivityVal && contrastSensitivityVal?.actual_user === uid) {
        setContrastSensitivityStatus(contrastSensitivityVal);
      }
      else {
        setContrastSensitivityStatus({
          in_test: false
        });
      }
    };
    onValue(contrastSensitivityStatusRef, fetchConstrastSensitivityStatus);

    return () => {
      off(contrastSensitivityStatusRef, 'value', fetchConstrastSensitivityStatus);
    };
  }, [isAppAlive]);
  // endregion

  // region PDF generation
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const startGeneratingPDF = () => setGeneratingPDF(true);
  const stopGeneratingPDF = () => setGeneratingPDF(false);
  // endregion

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  // region Snackbar logic
  const handleSnackbar = () => {
    setSnackbarMessage(t('generalTestLocale.success'));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  // region Form validation
  const allFieldsValid = () => {
    return eye;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      config_test_type: "contrast_sensitivity",
      config_eye: eye,
      config_contrast_sensitivity_cpd: cpd,
      config_user_id: uid,
    };
    const userId = currentUser.uid;

    axios
      .post(
        `${SERVER_URL}/server/dicoptpro-config/${userId}`,
        formData
      )
      .then((response) => {
        console.log("Form submitted successfully", response.data);

        handleSnackbar();
        updateLastTestDate(uid);
      })
      .catch((error) => {
        console.error("Error submitting form", error);
      });

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  // endregion

  // region HTML
  return (
    <React.Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom>
          {t('contrastSensitivityTestLocale.contrastSensitivity')}
        </Typography>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              {t('generalTestLocale.parametersTitle')}
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t('generalTestLocale.eye')}
              </InputLabel>
              <Select
                value={eye}
                label="Eye"
                onChange={(e) => setEye(e.target.value)}
              >
                <MenuItem value="right">{t('generalTestLocale.right')}</MenuItem>
                <MenuItem value="left">{t('generalTestLocale.left')}</MenuItem>
                <MenuItem value="none">{t('campimetryTestLocale.both')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              {t('generalTestLocale.optionsTitle')}
            </Typography>

            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="cpd-value"
                style={{
                  // Adjust the style to allow for wrapping and ensure adequate spacing
                  whiteSpace: "normal", // Allow the label to wrap onto multiple lines as needed
                  lineHeight: "1.4375em", // Standard line height to maintain vertical rhythm
                  paddingTop: "6px", // Add a bit of padding to the top to ensure wrapped text doesn't touch the input border
                }}
              >
                {t('contrastSensitivityTestLocale.cpd')}
              </InputLabel>
              <Select
                label={t('contrastSensitivityTestLocale.cpd')} // Ensure this matches the text of the InputLabel for proper alignment and sizing
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  name: "cpd-value",
                  id: "cpd-value",
                }}
                value={cpd}
                onChange={(e) => setCPD(e.target.value)}
              >
                <MenuItem value="1.5">1.5 CPD</MenuItem>
                <MenuItem value="3">3 CPD</MenuItem>
                <MenuItem value="6">6 CPD</MenuItem>
                <MenuItem value="12">12 CPD</MenuItem>
                {/*<MenuItem value="18">18 CPD</MenuItem>*/}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ textAlignLast: "center" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!allFieldsValid()} // Disable the button if not all fields are valid
            sx={{ width: 150, alignSelf: "center", marginTop: 2 }}
          >
            {t('formsLocale.submit')}
          </Button>
        </Box>
        {/* Divider and additional components */}
        <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          p={1}
          textAlign={"-webkit-center"}
        >
          <StartTestButton />
        </Box>

        <ContrastSensitivity12 contrastSensitivityStatus={contrastSensitivityStatus} />

        <Box sx={{ textAlignLast: "center", pt: 2 }}>
          <Button
            variant="contained"
            color="success"
            sx={{ width: 200, alignSelf: "center", marginTop: 2, mx: 2 }}
            onClick={startGeneratingPDF}
          >
            {t('pdfLocale.downloadPDF')}
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
          sx={{
            marginLeft: 15,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>

      <ContrastSensitivityPDF
        isGenerating={generatingPDF}
        stopGenerating={stopGeneratingPDF}
        userUID={currentUser.uid}
        patientUID={uid}
        contrastData={contrastSensitivityStatus}
        contrastEye={contrastSensitivityStatus?.status_eye === "right" ? 1 : contrastSensitivityStatus?.status_eye === "left" ? 0 : 2}
      >
      </ContrastSensitivityPDF>
    </React.Fragment>
  );
};

export default ContrastSensitivityForm;
